.pipeline-card{
    cursor: pointer;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    background-color: #b2bdce;
}

.pipeline-card div div{
    font-size: 0.8em !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 800px !important;
        margin: 1.75rem auto;
    }
}