.uppy-Dashboard, .uppy-Dashboard-inner{
    height: auto;
    max-height: 450px;
}

.uppy-Dashboard-innerWrap,
.uppy-Dashboard {
    width: 100%;
    padding: 10px;
    border: none !important;
    background-color: #fff !important;
}

.uppy-Dashboard-innerWrap{
    height: 100%;
}

.uppy-Container, .uppy-Root, .uppy-Dashboard, .uppy-Dashboard-inner{
    width: 100% !important;
   
}
.uppy-Dashboard-AddFiles{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}
.uppy-Dashboard-AddFiles-title{
    height: 100%;
    font-size: 1.2rem;
    margin-top: 150px
}
button.uppy-StatusBar-actionBtn {
    background-color: #8cb157  !important;
    border: none !important;
}

.uppy-u-reset.uppy-c-btn.uppy-Dashboard-browse{
    color: #8cb157  !important;
    border-color: #8cb157  !important;
}

.uppy-DashboardContent-addMore {
   color: #8cb157  !important;
}

.uppy-DashboardContent-back{
    color: #f3a4b5  !important;
    font-weight: 600 !important;
}

.uppy-Dashboard-poweredBy {
    display: none !important;
}