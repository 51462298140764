.print-image { 
    display: none;
}
@media print {
    body {
        padding-top: 1.5in;
    }
    .print-image {
        display: block;
        top: 0;
        left: 0.35in;
        position: absolute;
        z-index: 2000;
        max-width: 150px;
    }
    .sidenav, .nav-top, .header, .no-print {
        display: none !important;
    }
    body {
        font-size: 12pt;
    }
    @page {
        margin: 1in;
    }
}

.invisible {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
  